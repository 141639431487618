












































































import { defineComponent, computed } from '@vue/composition-api'
import { chain, sumBy } from 'lodash'

import { useQuery, useShowError } from 'hooks'
import { urlPath, endpoints } from 'utils'
import { HarvestOrderState } from 'typings'

import { AddButton, LoaderComponent, StepButton } from 'components'

const OrderDialog = defineComponent({
  components: {
    AddButton,
    LoaderComponent,
    StepButton
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { root }) {
    const { $router, $store, $toast } = root

    const harvestOrderState = computed(
      () => $store.state.harvest.harvestOrderState as HarvestOrderState
    )

    const title = computed(() => {
      const { item, variety, size, quality } = harvestOrderState.value
      const qualitySizeName = `${quality?.name || ''}${size?.name || ''}`
      return `${item?.name || ''}${variety?.name || ''} ${qualitySizeName}`
    })

    const endPoint = computed(() => {
      if (!props.value) return null

      const ids = harvestOrderState.value.ids.join(',')
      return `${endpoints.ORDER_DETAILS}by_harvest?ids=${ids}`
    })

    const { data: orderDetails, loading, error, isValidating } = useQuery(() => endPoint.value)
    useShowError($toast, error)

    const orderDetailGroup = computed(() => {
      if (!orderDetails.value) return []

      // Group order detail by auction date and customer
      const data = chain(orderDetails.value)
        .groupBy('auctionDate')
        .mapValues((values) =>
          chain(values)
            .groupBy('customerId')
            .map((value, key) => ({
              value,
              id: key,
              orderStems: sumBy(value, 'stems'),
              assignStems: sumBy(value, 'assignStems')
            }))
            .value()
        )
        .map((value, key) => ({
          auctionDate: key,
          customers: value,
          orderStems: sumBy(value, 'orderStems'),
          assignStems: sumBy(value, 'assignStems')
        }))
        .value()

      return data
    })

    const createOrder = (): void => {
      $router
        .push({
          name: urlPath.ORDER_DETAIL_FORM.name,
          params: {
            orderId: '0',
            detailId: '0'
          },
          query: { called: 'true' }
        })
        .catch((e) => {
          console.log(e)
        })
    }

    return { createOrder, loading, isValidating, orderDetailGroup, title }
  }
})
export default OrderDialog
